import {Button, Grid, Link, styled, Typography} from "@mui/material";
import {ExpandLess} from "@mui/icons-material";


export const Page = styled(Grid)({
    width: '100%',
});

export const Content = styled(Grid)({
    width: '1280px',
});

export const Title = styled(Grid)({
    padding: '24px'
});

export const Info = styled(Link)({
    cursor: 'pointer',
    fontSize: '12px',
    '&:hover': {
        color: '#434242'
    }
});

export const Filters = styled(Grid)({
    background: '#f6f6f6',
    borderRadius: '32px',
    width: '100%',
    height: 'auto',
    padding: '24px'
});

export const OpenFilterButton = styled(Button)({
    marginBottom: '15px',
    '&:hover': {
        backgroundColor: '#f6f6f6'
    }
});

export const FiltersLabel = styled(Typography)({
    textTransform: 'initial',
    fontSize: '18px',
    fontFamily: 'ALS Sirius',
    fontWeight: 500,
    color: 'black',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
});

export const FiltersLabelActive = styled(FiltersLabel)(({theme}) => ({
    color: theme.palette.error.main
}));

export const ExpandLessIcon = styled(ExpandLess)({
    transition: '0.3s',
    color: 'black'
});

export const ExpandLessIconActive = styled(ExpandLessIcon)(({theme}) => ({
    color: theme.palette.error.main
}));

export const TitleFieldNmc = styled(Typography)({
    marginTop: "25px",
    marginRight: "15px",
    fontFamily: "ALS Sirius, Helvetica, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#737373"
});

export const TitleFieldDate = styled(Typography)({
    marginTop: "25px",
    marginLeft: "15px",
    fontFamily: "ALS Sirius, Helvetica, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#737373"
});
export const Purchase = styled('div')({
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    borderBottom: "2px solid #F6F6F6",
    paddingTop: "40px",
    paddingBottom: "20px",
    fontFamily: "ALS Sirius, Helvetica, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: '100px',
});


export const PurchaseHeader = styled('div')({
    display: "flex",
    flexDirection: "column",
    gap: "24px"
});

export const PurchaseHeaderTop = styled(PurchaseHeader)({
    gap: "16px"
});

export const PurchaseInfo = styled(PurchaseHeader)({
    gap: "8px"
});

export const PurchaseClient = styled('div')({
    display: "flex",
    alignItems: "center",
    gap: "13px"
});

export const PurchaseName = styled('div')({
    fontSize: "24px",
    lineHeight: "32px"
});

export const PurchaseStatus = styled('div')({
    display: "flex",
    alignItems: "center",
    gap: "12px",
    ">span": {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        paddingLeft: "16px"
    },
    "&-open": {backgroundImage: "url(../img/status-open.svg)"},
    "&-default": {backgroundImage: "url(../img/status-default.svg)"},
    "&-cancel": {backgroundImage: "url(../img/status-cancel.svg)"},
    "&-progress": {backgroundImage: "url(../img/status-progress.svg)"}
});


export const PurchaseHeaderBottom = styled('div')({
    display: "flex",
    gap: "40px"
});


export const PurchaseHeaderBottomItem = styled('div')({
    display: "flex",
    flexDirection: "column",
    gap: "4px"
});


export const PurchaseHeaderBottomItemDiv = styled('div')({
    "&:first-child": {
        color: "#9B9B9B"
    }
});


export const PurchaseHeaderBottomItemSpan = styled('span')({
    fontSize: "14px",
    lineHeight: "20px"
});

export const PurchaseTableHead = styled('th')({
    padding: "16px 17px",
    textAlign: "left",
    backgroundColor: "#F6F6F6",
    fontSize: "18px",
    fontWeight: 500,
    "&:first-child": {borderRadius: "16px 0 0 16px"},
    "&:last-child": {borderRadius: "0 16px 16px 0"}
});
export const PurchaseTableBody = styled('td')({
    fontSize: "18px",
    fontWeight: 500,
    padding: "16px 17px",
    textAlign: "left"
});
export const PurchaseTableBodyAction = styled(PurchaseTableBody)({
    background: 'white'
});

export const TableAction = styled('p')({
    cursor: 'pointer',
    color: '#FA4022'
});


export const PurchaseTable = styled('table')({
    borderSpacing: "0",
    borderRadius: "16px 16px 0 0",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {backgroundColor: "#E6E6E6"},
    width: '100%'
});
export const Footer = styled('div')({
    backgroundColor: "#F6F6F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 30px",
    position: 'fixed',
    bottom: 0,
    width: 'inherit',
});
export const FooterWrap = styled('div')({
    display: "flex",
    gap: "48px"
});
export const FooterButton = styled(Button)({
    backgroundColor: "#434242",
    color: "#fff",
    cursor: "pointer",
    padding: "12px 20px",
    borderRadius: "12px",
    fontFamily: "ALS Sirius, Helvetica, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    width: 'unset',
    height: 'unset',
    borderBottom: 'unset',
    textTransform: 'unset',
    transition: '0.3s',
    border: '2px solid #434242',
    ':hover': {
        backgroundColor: "transparent",
        border: '2px solid #434242',
        color: "#434242",
    }
});
export const ApplyFiltersButton = styled(Button)({
    backgroundColor: "transparent",
    border: '2px solid #434242',
    color: "#434242",
    cursor: "pointer",
    padding: "8px 10px",
    borderRadius: "12px",
    fontFamily: "ALS Sirius, Helvetica, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: 'unset',
    transition: '0.3s',
    ':hover': {
        border: '2px solid #434242',
        backgroundColor: '#434242',
        color: 'white'
    }
});
export const ClearFiltersButton = styled(ApplyFiltersButton)({
    border: 'unset',
    ':hover': {
        border: 'unset',
        backgroundColor: 'transparent',
        color: '#616161'
    }
});
export const Dust = styled('div')({
    width: "25px",
    borderBottom: "2px solid black",
    marginLeft: "10px",
    marginTop: "35px",
    height: "5px"
});
import * as React from 'react';
import {Info} from "../styles/guest";
import {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Grid, Link} from "@mui/material";

export const Information = () => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <Info
        onClick={() => setOpen(true)}
      >
        Полезная информация заказчикам и участникам закупок
      </Info>
      {open ? (
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>
            Полезная информация заказчикам и участникам закупок
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Grid item>
                <Link target="_blank" href="https://zakupki.gov.ru/epz/main/public/home.html">Единая информационная система в сфере закупок</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://minfin.gov.ru">Сайт Минфина России</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://fas.gov.ru/?ysclid=m3owgtfpz6738878688">ФАС России</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://minfin.gov.ru/ru/perfomance/contracts/purchases/?ysclid=m3ow7q4jbi56130018">Отчет о мониторинге закупок</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://minfin.gov.ru/ru/perfomance/contracts/application/#zakonodatelstvo_o_kontraktnoi_sisteme_v_sfere_zakupok">Методическое обеспечение (информационные письма и материалы)</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://minfin.gov.ru/ru/perfomance/contracts/list_banks">
                  Перечень банков, которые вправе выдавать независимые гарантии для обеспечения заявок и исполнения контрактов и соответствующих требованиям, установленным частями 1 и 1.1 статьи 45 Федерального закона от 05.04.2013 № 44-ФЗ «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд»
                </Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://minfin.gov.ru/ru/perfomance/contracts/list_org">
                  Перечень региональных гарантийных организаций, которые вправе выдавать независимые гарантии для целей участия в закупках в соответствии с Федеральным законом от 05.04.2013 № 44-ФЗ
                </Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://br.fas.gov.ru">База решений и правовых актов ФАС России</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://fas.gov.ru/spheres/5">Контроль госзакупок ФАС России</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://fas.gov.ru/pages/reestr_kompanij_nedostoverno">Проверьте наличие участника закупки в Реестре недостоверных сведений, представленных участниками закупок</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://zakupki.gov.ru/epz/main/public/document/view.html?sectionId=920">ГИС «Независимый регистратор»</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://экг-рейтинг.рф/">Проверьте ЭКГ-рейтинг участника закупки</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://egrul.nalog.ru/index.html?amp;">ПРЕДОСТАВЛЕНИЕ СВЕДЕНИЙ ИЗ ЕГРЮЛ/ЕГРИП В ЭЛЕКТРОННОМ ВИДЕ</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://zakupki.gov.ru/epz/main/public/document/view.html?searchString=&sectionId=2369&strictEqual=false">Информация о привлечении участника закупки к административной ответственности по ст. 19.28 КоАП</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://zakupki.gov.ru/epz/dishonestsupplier/search/results.html">Реестр недобросовестных поставщиков (подрядчиков, исполнителей, подрядных организаций) (44-ФЗ, 223-ФЗ, ПП РФ-615)</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://kad.arbitr.ru/?ysclid=m3owtms4gk899496194">Проверьте участника закупки по картотеке арбитражных дел</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://forum-goszakaz.ru/?ysclid=m3owvtz3ti696583560">Форум-выставка «ГОСЗАКАЗ»</Link>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
    </Fragment>
  )
}

import * as React from 'react';
import {Info} from "../styles/guest";
import {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Grid, Link} from "@mui/material";

export const Guide = () => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <Info
        onClick={() => setOpen(true)}
      >
        Руководства пользователей
      </Info>
      {open ? (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>
            Руководства пользователей
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Grid item>
                <Link target="_blank" href="https://docs.google.com/document/d/10AtpiQDSX-AnLVV_b6zY3zj9Z8Wob-uHR3Wu5PGyMhs/edit?usp=sharing">Инструкция поставщика по работе с ситемой</Link>
              </Grid>
              <Grid item>
                <Link target="_blank" href="https://docs.google.com/document/d/1m1DvyK292KXcLZPhkIyHmLHod4-ZSPWV6tg99CKWvm8/edit?usp=sharing">Инструкция заказчика по работе с ситемой</Link>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
    </Fragment>
  )
}

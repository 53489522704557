import * as React from 'react';
import {Info} from "../styles/guest";
import {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Grid, Link} from "@mui/material";

export const Support = () => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <Info
        onClick={() => setOpen(true)}
      >
        Поддержка
      </Info>
      {open ? (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>
            Поддержка
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                  <Grid item>
                    Время работы:
                  </Grid>
                  <Grid item>
                    с 9:30 до 18:30 по московскому времени
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                  <Grid item>
                    Телефон:
                  </Grid>
                  <Grid item>
                    +7 (495) 248-06-46
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                  <Grid item>
                    Электронная почта:
                  </Grid>
                  <Grid item>
                    <Link href="mailto:support@emsoft.ru">
                      support@emsoft.ru
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
    </Fragment>
  )
}
